/* src/App.css */

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

.app {
  padding: 20px;
}

header {
  text-align: center;
  padding: 10px 0;
}

header h1 {
  font-size: 1.8em;
}

header nav {
  margin-top: 20px;
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically align (if needed) */
  flex-wrap: wrap; /* Allow buttons to wrap to the next line on small screens */
  gap: 10px;
}

header button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #e0e0e0;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, color 0.3s;
}

header button.active {
  background-color: #007bff;
  color: white;
}

header button:hover:not(.active) {
  background-color: #d6d6d6;
}

.table-container {
  margin-top: 40px;
  text-align: center;
}

h2 {
  font-size: 1.5em;
  margin-top: 20px;
}

table {
  width: 100%;
  max-width: 90%;
  margin: 20px auto;
  border-collapse: collapse;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-size: 0.9em;
}

th,
td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

th:hover {
  background-color: #f0f0f0;
}

tbody tr:hover {
  background-color: #f9f9f9;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  header h1 {
    font-size: 1.5em;
  }

  header nav {
    flex-direction: column;
  }

  header button {
    width: 90%;
    padding: 8px;
    font-size: 0.9em;
  }

  table {
    font-size: 0.8em;
    max-width: 100%;
    overflow-x: auto;
    display: block;
  }

  th,
  td {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  header h1 {
    font-size: 1.2em;
  }

  h2 {
    font-size: 1.2em;
  }

  header button {
    font-size: 0.8em;
  }
}
